import util from '../../../util';

class User {
  #uid;
  set uid(value) {
    if (typeof value === 'undefined' || value === null) {
      this.#uid = '';
    } else {
      this.#uid = value;
    }
  }
  get uid() {
    return this.#uid;
  }

  #lastName
  set lastName(value) {
    if (!value) {
      throw new Error('lastName is a required field.');
    }
    this.#lastName = value;
  }
  get lastName() {
    return this.#lastName;
  }

  #firstName
  set firstName(value) {
    if (!value) {
      throw new Error('firstName is a required field.');
    }
    this.#firstName = value;
  }
  get firstName() {
    return this.#firstName;
  }

  // Full Name
  get name() {
    return `${this.#lastName} ${this.#firstName}`;
  }

  #lastNameKana;
  set lastNameKana(value) {
    if (!value) {
      throw new Error('lastNameKana is a required field.');
    }
    this.#lastNameKana = value;
  }
  get lastNameKana() {
    return this.#lastNameKana;
  }

  #firstNameKana;
  set firstNameKana(value) {
    if (!value) {
      throw new Error('firstNameKana is a required field.');
    }
    this.#firstNameKana = value;
  }
  get firstNameKana() {
    return this.#firstNameKana;
  }

  // Full NameKana
  get nameKana() {
    return `${this.#lastNameKana} ${this.#firstNameKana}`;
  }

  #email;
  set email(value) {
    if (!value) {
      throw new Error('email is a required field.');
    }
    this.#email = value;
  }
  get email() {
    return this.#email;
  }

  #status;
  set status(value) {
    if (typeof value !== 'boolean') {
      throw new Error('status must be an boolean.');
    }
    this.#status = value;
  }
  get status() {
    return this.#status;
  }

  #shops;
  set shops(value) {
    if (!value) {
      throw new Error('shops is a required field.');
    }
    if (!Array.isArray(value)) {
      throw new Error('shops must be an Array.');
    }
    this.#shops = value.map(shop => {
      if (!shop.id) {
        throw new Error('"shop.id" is a required field.');
      }
      return shop.id;
    });
  }
  get shops() {
    return this.#shops;
  }

  constructor(user) {
    this.uid = user.uid;
    this.lastName = user.lastName;
    this.firstName = user.firstName;
    this.lastNameKana = user.lastNameKana;
    this.firstNameKana = user.firstNameKana;
    this.email = user.email;
    this.status = user.status;
    this.shops = user.shops;
  }

  toObjectUser() {
    return {
      uid: this.uid,
      lastName: this.lastName,
      firstName: this.firstName,
      lastNameKana: this.lastNameKana,
      firstNameKana: this.firstNameKana,
      email: this.email,
      status: this.status,
      shops: this.shops,
    };
  }
}

export class Member extends User {
  #roll = 'member';
  get roll() {
    return this.#roll;
  }

  #payid;
  set payid(value) {
    if (typeof value === 'undefined' || value === null) {
      this.#payid = '';
    } else {
      this.#payid = value;
    }
  }
  get payid() {
    return this.#payid;
  }

  #membership;
  set membership(value) {
    if (value !== '入会中' && value !== '休会' && value !== '退会') {
      throw new Error('membership must be one of the following."入会中","休会","退会"');
    }
    this.#membership = value;
  }
  get membership() {
    return this.#membership;
  }

  #lefty;
  set lefty(value) {
    if (typeof value !== 'boolean') {
      throw new Error('lefty must be an boolean.');
    }
    this.#lefty = value;
  }
  get lefty() {
    return this.#lefty;
  }

  constructor(user) {
    super(user);
    this.payid = user.payid;
    this.membership = user.membership;
    this.lefty = user.lefty;
  }

  create(password) {
    this.membership = '入会中';
    const param = this.toObject();
    param.password = password;
    return util.callCloudFunction('addUser', param);
  }

  toObject() {
    const data = this.toObjectUser();
    data.roll = this.roll;
    data.payid = this.payid;
    data.membership = this.membership;
    data.lefty = this.lefty;
    return data;
  }
}

export class Instructor extends User {
  #roll = 'instructor';

  constructor(user, weekdayShift, holidayShift, phonenumber) {
    super(user);
    this.weekdayShift = weekdayShift;
    this.holidayShift = holidayShift;
    this.phonenumber = phonenumber;
  }
}

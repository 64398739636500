<template>
  <v-app>
    <v-card width="500px" class="mx-auto my-auto">
      <v-toolbar color="#0673b2" flat dark>
        <v-img
          max-height="190"
          max-width="170"
          src="../assets/strong-golf.png"
        ></v-img>
      </v-toolbar>
      <v-card-text>
        <p>下記の項目を入力し<span class="font-weight-bold"> [予約専用アカウント] </span>を作成してください。</p>
        <v-form ref='form' lazy-validation>
          <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  メールアドレス
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  ログイン時に利用するメールアドレスです。必ずメールを受信できるアドレスを指定してください。また、メールアドレスは後から変更できません。
                </p>
                <v-text-field
                  v-model='member.email'
                  outlined
                  dense
                  type='email'
                  validate-on-blur
                  :rules='[formRules.required, formRules.email]'
                ></v-text-field>
              </v-col>
              <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  パスワード
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  8 文字以上 20 文字以内で指定してください。半角数字、半角英字をそれぞれ 1 文字以上含める必要があります。
                </p>
                <v-text-field
                  v-model='password'
                  outlined
                  dense
                  validate-on-blur
                  v-bind:type="showPassword ? 'text' : 'password'"
                  v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append='showPassword = !showPassword'
                  :rules='[formRules.required, formRules.password]'
                ></v-text-field>
              </v-col>
          <v-col cols='12' class='py-0'>
                <p class='mb-0 text--primary'>
                  お名前
                  <span class='required-label'>
                    必須
                  </span>
                </p>
                <p
                  class='mb-0 text--secondary v-messages'
                  style='line-height: 1.5;'
                >
                  フリガナは全角カタカナで入力してください。
                </p>
                <v-row>
                  <v-col cols='12' class='d-flex justify-space-between pb-0'>
                    <v-text-field
                      v-model='member.lastName'
                      outlined
                      dense
                      validate-on-blur
                      :rules='[formRules.required]'
                      placeholder='姓：山田'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='member.firstName'
                      outlined
                      dense
                      validate-on-blur
                      :rules='[formRules.required]'
                      placeholder='名：太郎'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' class='d-flex justify-space-between pt-0'>
                    <v-text-field
                      v-model='member.lastNameKana'
                      outlined
                      dense
                      validate-on-blur
                      :rules='[formRules.required, formRules.kana]'
                      placeholder='セイ：ヤマダ'
                    ></v-text-field>
                    <p class='mx-2'></p>
                    <v-text-field
                      v-model='member.firstNameKana'
                      outlined
                      dense
                      validate-on-blur
                      :rules='[formRules.required, formRules.kana]'
                      placeholder='メイ：タロウ'
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
          <v-checkbox v-model="member.lefty" label="レフティ（左打ち）の方はチェックを入れてください。"></v-checkbox>
          <v-card-actions>
            <v-btn block color="#0673b2" dark @click='save' :loading="loading">予約専用アカウントを作成する</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import util from '../util';
import { UserInterface } from '../domain/model/User/UserInterface';

export default {
  data() {
    return {
      showPassword: true,
      loading: false,
      password: '',
      member: {
        shops: [],
        email: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        lefty: false,
        status: true,
        membership: '入会中',
      },
      formRules: {
        required: value => !!value || '必ず入力してください。',
        requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
        limit_length: value => (value || '').length <= 0 || '20文字以内で入力してください。',
        password: value => /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!"#$%&''()\-^@[;:,.+*?_]{8,20}$/.test(value) || '半角数字と半角英字の2種類を含む8文字以上20文字以内で入力してください。',
        email: value => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'メールアドレスの形式が正しくありません。',
        tel: value => /^(0\d{1,4}-\d{1,4}-\d{3,4})?$/.test(value) || '電話番号の形式が正しくありません。',
        kana: value => /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/.test(value) || '全角カタカナで入力してください。',
      },
    };
  },
  async mounted() {
    const shops = await util.callCloudFunction('getShop');
    // TODO 現在は練馬店で固定
    this.member.shops.push(shops.find((v) => v.name === '練馬店'));
  },
  methods: {
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      await UserInterface.createMember(this.member, this.password).then(() => {
        this.$store.commit('snackbar/open', { text: 'アカウントを作成しました。自動でログインします。', color: 'success' });
        this.$store.dispatch('singIn', { email: this.member.email, password: this.password })
          .then(() => {
            this.$router.push('/entry');
            this.loading = false;
          });
      }).catch((res) => {
        const msg = !res ? 'アカウントの作成に失敗しました。再度お試しください。' : res;
        this.$store.commit('snackbar/open', { text: msg, color: 'error' });
      });
      this.loading = false;
    },
    toLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
